import '@scss/gatstrap.scss'
import React from 'react'
import { Container } from 'react-bootstrap'
import Helmet from 'react-helmet'

// markup
const HomePage = ({ data, filename }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const siteKeywords = data.site.siteMetadata.keywords

  return (
    <div className="wrapper">
      <Helmet
        htmlAttributes={{
          lang: 'ja',
        }}
      >
      </Helmet>
      <header className="bg-dark">
        <div className="d-flex justify-content-center align-content-center py-3">

        </div>
      </header>
      <main className="py-5">
        <Container>
          <div className="py-1">
              <h1>アプリケーション・プライバシーポリシー</h1>
              <p>
                本アプリケーションを利用するにあたり、以下のプライバシーポリシーが適用されます。 お客様は、本アプリケーションを利用することにより、本アプリケーション・ポリシーに同意したものとみなされます。
              </p>
              <p>
                本アプリケーション・ポリシーにおいて、個人情報とは、個人情報保護法により定義された個人情報及び特定の個人情報に結びついて使用される個人に関する属性情報（メールアドレス）を意味するものとします。
              </p>
              <h2 className="mt-5">個人情報の管理</h2>
              <p>
                本アプリではお客さまの個人情報を保持するにあたり、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するために適切な措置を講じ、安全対策を実施し個人情報の管理を行ないます。
              </p>
              <h2 className="mt-5">個人情報の利用目的</h2>
              <p>
                取得する情報、利用目的、第三者への提供等につきましては、以下の広告配信事業者のアプリケーション・プライバシーポリシーのリンクよりご確認ください。
                <br />
                Google 広告に関するポリシー
                <a href="https://policies.google.com/technologies/ads?hl=ja">
                  https://policies.google.com/technologies/ads?hl=ja
                </a>
              </p>
              <h2 className="mt-5">利用状況解析ついて</h2>
              <p>本アプリでは、今後の開発の参考とするため、アプリの利用状況データを収集するツールとしてFirebase(Google
                Inc.)を使用しており、Firebaseがご利用者の情報を自動取得する場合がございます。取得する情報、利用目的、第三者への提供等につきましては、以下のGoogleプライバシーポリシーのリンクよりご確認ください。</p>
              Google プライバシーポリシー
              <a
                href="https://policies.google.com/privacy?hl=ja">https://policies.google.com/privacy?hl=ja</a>
              <h2 className="mt-5">個人情報の第三者への開示・提供の禁止</h2>
              <p>お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</p>
              <li>お客さまの同意がある場合</li>
              <li>法令に基づき開示することが必要である場合</li>
              <li>司法または行政機関から開示を求められた場合</li>
              <h2 className="mt-5">個人情報の安全対策</h2>
              個人情報の正確性及び安全性確保のために、十分なセキュリティに万全の対策を講じ、万が一、問題が発生した場合は、速やかに適当な是正対策をします。
              <h2 className="mt-5">法令、規範の遵守と見直し</h2>
              保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
          </div>
        </Container>
      </main>
      <footer>
        <p>Copyright © 2021 Forestone All Rights Reserved.</p>
      </footer>
    </div>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allFile {
      edges {
        node {
          relativePath
          name
        }
      }
    }
  }
`
